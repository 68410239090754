<template>
    <el-input :placeholder="sonPlaceholder" v-model="val">
        <el-button slot="append" icon="el-icon-search" @click="btn"></el-button>
    </el-input>
</template>
<script>
export default {
    props:['sonPlaceholder'],
    data(){
        return{
            val:''
        }
    },
    methods:{
        btn(){
            this.$emit('sonsearchBut',this.val)
        },
        reset(){
            this.val=''
        }
    }
}
</script>
<style lang="less" scoped>
    .el-input{
        width: 348px;
        min-width: 348px;
        margin-right: 40px;
        
    }
    /deep/.el-input-group__append, .el-input-group__prepend{
        background-color: #FF6056;
        color: #ffffff;
        border:none;
    }
</style>