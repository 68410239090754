<template>
    <!-- @current-change="handleCurrentChange"监听页码值发生变化时，调用handleCurrentChange -->
    <!-- @size-change="handleSizeChange"监听pagesize改变 -->
    <!-- :current-page="currentPage4"  当前是第几页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="sonpagingData.page"
      :page-sizes="[15, 30, 45, 60]"
      :page-size="sonpagingData.max_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="sonpagingData.count">
    </el-pagination>
</template>
<script>
export default {
    props:['sonpagingData'],
    data() {
      return {
        currentPage4: 4
      };
    },
    methods: {
      // 监听pagesize改变
      handleSizeChange(val) {
        this.$emit('sonHandleSizeChange',val)
      },
      // 监听页码值
      handleCurrentChange(val) {
        this.$emit('sonHandleCurrentChange',val)
      }
    }
}
</script>
<style lang="less" scoped>
    .el-pagination{
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>