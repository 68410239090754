<template>
    <div class="box">
        <div class="title">
            <div class="icon"></div>
            订单信息
        </div>
        <div class="content">
            <div class="list">
                <div class="list-title"><div>·</div>订单详情</div>
                <div class="list-item">
                    <div class="list-item-l">订单ID</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_id">{{sonorderDetails.order_id}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">订单状态</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_status">{{sonorderDetails.order_status}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">下单用户ID</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.user">{{sonorderDetails.user}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">订单类型</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_type=='EAT_IN'">堂食</span>
                        <span v-else-if="sonorderDetails.order_type=='TAKE_AWAY'">外带</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">品牌名称</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.brand_name">{{sonorderDetails.brand_name}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <!-- <div class="list-item" style="align-items:flex-start;">
                    <div class="list-item-l">订单时间轴</div>
                    <div class="list-item-r">
                        <ul style="margin:0;">
                            <li v-for="(item,index) in sonorderDetails.time_axis" :key="index">
                                {{item}}
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div class="list-item">
                    <div class="list-item-l">订单实付</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_amount">{{sonorderDetails.order_amount}} 元</span>
                        <span v-else>-</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="title">
            <div class="icon"></div>
            商品信息
        </div>
        <div class="content">
            <div class="list">
                <div class="list-title"><div>·</div>订单详情</div>
                <div class="list-item" style="align-items:flex-start;">
                    <div class="list-item-l">商品详情</div>
                    <!-- 喜茶 -->
                    <div class="product-details" v-if="sonorderDetails.brand_id==19">
                        <div class="product-details-item" v-for="(item,index) in sonorderDetails.product_info" :key="index">
                            <img :src="item.product_img" alt="" srcset="">
                            <div class="product-details-item-r">
                                <div class="name">
                                    {{item.product_name}}  
                                    × 
                                    {{item.amount}}
                                    ￥ 
                                    {{item.user_price}}</div>
                                <div class="accessories">
                                    <div class="accessories" v-for="(item1,key,index1) in item.material_data" :key="index1">
                                        <span v-if="index1!==0">+</span>
                                        <div v-for="(item2,index2) in item1" :key="index2">
                                            {{item2.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 奈雪的茶，瑞辛咖啡 -->
                    <div class="product-details" v-else-if="sonorderDetails.brand_id==2||sonorderDetails.brand_id==13"> 
                        <div class="product-details-item" v-for="(item,index) in sonorderDetails.product_info" :key="index">
                            <img :src="item.product_img" alt="" srcset="">
                            <div class="product-details-item-r">
                                <div class="name">{{item.product_name}}  × {{item.amount}} ￥ {{item.user_price}}</div>
                                <div class="accessories">
                                    <div class="accessories" v-for="(item1,index1) in item.accessories.specs" :key="index1">
                                        <span v-if="index1!==0">+</span>
                                        {{item1.name}}:{{item1.spec_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 星巴克 -->
                    <div class="product-details" v-else-if="sonorderDetails.brand_id==10">
                        <div class="product-details-item" v-for="(item,index) in sonorderDetails.product_info" :key="index">
                            <img :src="item.product_img" alt="" srcset="">
                            <div class="product-details-item-r">
                                <div class="name">{{item.product_name}}  × {{item.amount}} ￥ {{item.user_price}}</div>
                                <div class="accessories">
                                    <div class="accessories" v-for="(item1,index1) in item.accessories" :key="index1">
                                        <span v-if="index1!==0">+</span>
                                        {{item1.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <!-- 麦当劳,肯德基 -->
                    <div class="product-details" v-else-if="sonorderDetails.brand_id==5||sonorderDetails.brand_id==1">
                        <div class="product-details-item" v-for="(item,index) in sonorderDetails.product_info" :key="index">
                            <img :src="item.product_img" alt="" srcset="">
                            <div class="product-details-item-r">
                                <div class="name">{{item.product_name}}  × {{item.amount}} ￥ {{item.user_price}} 元</div>
                                <div class="accessories">
                                    <div class="accessories">
                                        {{item.product_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 其他类型 -->
                    <div class="product-details" v-else>
                        <div class="product-details-item">
                            <div class="product-details-item-r">
                                <div class="name">{{sonorderDetails.product_name}}  × {{sonorderDetails.count}} ￥ {{sonorderDetails.price}}</div>
                                <div class="accessories">
                                    <div class="accessories">
                                        {{sonorderDetails.product_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">商品总价</div>
                    <div class="list-item-r">{{sonorderDetails.total_amount}} 元</div>
                </div>
            </div>
            <div class="list">
                <div class="list-title"><div>·</div>注意事项</div>
                <div class="list-item">
                    <div class="text" v-html="sonorderDetails.api_use_explain"></div>
                </div>
                <div class="list-title"><div>·</div>门店信息</div>
                <div class="list-item">
                    <div class="list-item-l">门店名称</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.restaurant_name">{{sonorderDetails.restaurant_name}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['sonorderDetails'],
    data(){
        return{
        }
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
.box{
    padding:0 50px!important;
}
.title{
    height: 50px;
    display: flex;
    align-items: center;
    .icon{
        height: 14px;
        border-left: 2px solid #FF6056;
        border-right: 2px solid #FF6056;
        border-radius: 7px;
        margin-right: 5px;
        margin-top: 2px;
    }
}
.content{
    border: 1px solid #BABEC7;
    border-radius: 4px;
    padding: 0px 20px 20px 20px;
    .list-title{
        font-weight: 700;
        font-size: 14px;
        color: #252530;
        display: flex;
        margin-top: 20px;
        div{
            font-weight: 700;
            margin-right: 5px;
        }
    }
    .list-item{
        display: flex;
        align-items: center;
        padding: 8px 0;
        padding-left: 12px;
        .list-item-l{
            width: 96px;
            color: #828494;
            font-size: 14px;
        }
        .list-item-r{
            color: #252530;
            font-size: 14px;
        }
        .text{
            color: #828494;
            font-size: 14px;
        }
        .product-details{
            display: flex;
            flex-direction: column;
            .product-details-item{
                display: flex;
                margin-bottom: 10px;
                img{
                    width: 42px;
                    height: 42px;
                    margin-right: 15px;
                }
                .product-details-item-r{
                    display: flex;
                    flex-direction: column;
                    .name{
                        font-weight: 700;
                        color: #252530;
                        font-size: 14px;
                    }
                    .accessories{
                        display: flex;
                        font-size: 12px;
                        color: #828494;
                    }
                }
            }
            
        }
    }
}
</style>