<template>
    <div class="box">
        <div class="title">
            <div class="icon"></div>
            订单信息
        </div>
        <div class="content">
            <div class="list">
                <div class="list-title"><div>·</div>订单详情</div>
                <div class="list-item">
                    <div class="list-item-l">订单ID</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_id">{{sonorderDetails.order_id}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">订单状态</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_status">{{sonorderDetails.order_status}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">下单用户ID</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.user">{{sonorderDetails.user}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <!-- <div class="list-item">
                    <div class="list-item-l">订单类型</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_type=='EAT_IN'">堂食</span>
                        <span v-else-if="sonorderDetails.order_type=='TAKE_AWAY'">外带</span>
                        <span v-else>-</span>
                    </div>
                </div> -->
                <div class="list-item">
                    <div class="list-item-l">品牌名称</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.brand_name">{{sonorderDetails.brand_name}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">创建时间</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.create_time">{{sonorderDetails.create_time | dateFormat}}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <!-- <div class="list-item" style="align-items:flex-start;">
                    <div class="list-item-l">订单时间轴</div>
                    <div class="list-item-r">
                        <ul style="margin:0;">
                            <li v-for="(item,index) in sonorderDetails.time_axis" :key="index">
                                {{item}}
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div class="list-item">
                    <div class="list-item-l">订单实付</div>
                    <div class="list-item-r">
                        <span v-if="sonorderDetails.order_amount">{{sonorderDetails.order_amount}} 元</span>
                        <span v-else>-</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="title">
            <div class="icon"></div>
            商品信息
        </div>
        <div class="content">
            <div class="list">
                <div class="list-title"><div>·</div>订单详情</div>
                <div class="list-item" style="align-items:flex-start;">
                    <div class="list-item-l">商品详情</div>
                    <div class="product-details">
                        <div class="product-details-item">
                            <div class="product-details-item-r">
                                <div class="name">{{sonorderDetails.product_name}} × {{sonorderDetails.count}} ￥{{sonorderDetails.price}} 元</div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-item">
                    <div class="list-item-l">商品总价</div>
                    <div class="list-item-r">{{sonorderDetails.total_amount}} 元</div>
                </div>
            </div>
            <div class="list">
                <div class="list-title"><div>·</div>注意事项</div>
                <div class="list-item">
                    <div class="text" v-html="sonorderDetails.api_use_explain"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['sonorderDetails'],
    data(){
        return{
        }
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
.box{
    padding:0 50px!important;
}
.title{
    height: 50px;
    display: flex;
    align-items: center;
    .icon{
        height: 14px;
        border-left: 2px solid #FF6056;
        border-right: 2px solid #FF6056;
        border-radius: 7px;
        margin-right: 5px;
        margin-top: 2px;
    }
}
.content{
    border: 1px solid #BABEC7;
    border-radius: 4px;
    padding: 0px 20px 20px 20px;
    .list-title{
        font-weight: 700;
        font-size: 14px;
        color: #252530;
        display: flex;
        margin-top: 20px;
        div{
            font-weight: 700;
            margin-right: 5px;
        }
    }
    .list-item{
        display: flex;
        align-items: center;
        padding: 8px 0;
        padding-left: 12px;
        .list-item-l{
            width: 96px;
            color: #828494;
            font-size: 14px;
        }
        .list-item-r{
            color: #252530;
            font-size: 14px;
        }
        .text{
            color: #828494;
            font-size: 14px;
        }
        .product-details{
            display: flex;
            flex-direction: column;
            .product-details-item{
                display: flex;
                margin-bottom: 10px;
                img{
                    width: 42px;
                    height: 42px;
                    margin-right: 15px;
                }
                .product-details-item-r{
                    display: flex;
                    flex-direction: column;
                    .name{
                        font-weight: 700;
                        color: #252530;
                        font-size: 14px;
                    }
                    .accessories{
                        display: flex;
                        font-size: 12px;
                        color: #828494;
                    }
                }
            }
            
        }
    }
}
</style>